import { ButtonSocialText } from '@components/web-landingpage/Texts';
import { twMerge } from 'tailwind-merge';

export function IconButtonComponent({
  title,
  children,
  onClick,
  className,
}: {
  title: string;
  children?: any;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        `flex h-14 w-full items-center justify-center space-x-2 rounded-xl bg-marketing-button-secondary-enabled px-3 hover:bg-marketing-button-secondary-hover`,
        className,
      )}
    >
      {children}
      <ButtonSocialText className="ml-2 leading-[14px] text-marketing-button-secondary-text">
        {title}
      </ButtonSocialText>
    </button>
  );
}
