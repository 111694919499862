import {
  LoginOrSignupLocation,
  trackSignupGTM,
  trackSuccessfulSignin,
  trackSuccessfulSignup,
  trackTikTokEvent,
} from '@customtypes/events';
import { sendDataToCustomerIO } from '@requests/contactDetailsRequest';
import 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import { User } from 'next-firebase-auth';
import { builder } from '@builder.io/react';
import {
  UserCredential,
  getAdditionalUserInfo,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithPopup as firebaseSignInWithPopup,
  OAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
} from 'firebase/auth';
import { getApp } from 'firebase/app';

const app = getApp();
const auth = getAuth(app);

export const didLoginOrRegisterWeb = (
  userCredential: UserCredential,
  location: LoginOrSignupLocation,
) => {
  const { user, operationType } = userCredential;
  const additionalUserInfo = getAdditionalUserInfo(userCredential);

  if (user && additionalUserInfo?.isNewUser) {
    mixpanel.alias(user.uid);
  }
  if (user) {
    mixpanel.identify(user.uid);
  }
  sendDataToCustomerIO(user as any as User);

  if (operationType === 'signIn' && additionalUserInfo?.isNewUser) {
    builder.trackConversion();

    switch (additionalUserInfo?.providerId) {
      case 'password':
        trackSuccessfulSignup(mixpanel, {
          source: 'email',
          location,
        });
        trackSignupGTM({
          source: 'email',
        });
        trackTikTokEvent({
          email: user?.email,
          eventName: 'CompleteRegistration',
        });
        break;
      case 'google.com':
        trackSuccessfulSignup(mixpanel, {
          source: 'google',
          location,
        });
        trackSignupGTM({
          source: 'google',
        });
        trackTikTokEvent({
          email: user?.email,
          eventName: 'CompleteRegistration',
        });
        break;
      case 'apple.com':
        trackSuccessfulSignup(mixpanel, {
          source: 'apple',
          location,
        });
        trackSignupGTM({
          source: 'apple',
        });
        trackTikTokEvent({
          email: user?.email,
          eventName: 'CompleteRegistration',
        });
        break;
      default:
        break;
    }
  } else if (operationType === 'signIn') {
    switch (additionalUserInfo?.providerId) {
      case 'password':
        trackSuccessfulSignin(mixpanel, {
          source: 'email',
          location,
        });
        break;
      case 'google.com':
        trackSuccessfulSignin(mixpanel, {
          source: 'google',
          location,
        });
        break;
      case 'facebook.com':
        trackSuccessfulSignin(mixpanel, {
          source: 'facebook',
          location,
        });
        break;
      case 'apple.com':
        trackSuccessfulSignin(mixpanel, {
          source: 'apple',
          location,
        });
        break;
      default:
        break;
    }
  }
};

export const createUserWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  return firebaseCreateUserWithEmailAndPassword(auth, email, password);
};

export const signInWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  return firebaseSignInWithEmailAndPassword(auth, email, password);
};

export const signInWithApple = () => {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  return firebaseSignInWithPopup(auth, provider);
};

export const signInWithFacebook = () => {
  const provider = new FacebookAuthProvider();
  provider.addScope('public_profile');
  provider.addScope('email');
  return firebaseSignInWithPopup(auth, provider);
};

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  return firebaseSignInWithPopup(auth, provider);
};
